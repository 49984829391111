<template>
  <div>    
    <div class="card">
      <b-form-input v-model="text" placeholder="Enter your name"></b-form-input>
     <router-link :to="{ name: 'testcrd', params: { id: text }}"
                                ><div class="bidViewDetails">
                              View Details
                            </div></router-link > 
    </div> 
  </div>
</template>

<script>
  export default {
    data() {
      return {
        text: ''
      }
    }
  }
</script>